import type { GatsbyBrowser } from "gatsby";

import urlParamsToSend from "lib/url_params_to_send";

export { wrapPageElement } from "./wrapPageElement";

export const shouldUpdateScroll: GatsbyBrowser["shouldUpdateScroll"] = ({
  routerProps: { location },
}): boolean | [number, number] => {
  if (!location.hash) return true;

  setTimeout(() => {
    const item = document.querySelector<HTMLElement>(`${location.hash}`);
    if (!item) {
      window.scrollTo({ top: 0 });
      return;
    }

    const header = document.querySelector("header");
    const headerOffset = header ? header.clientHeight : 128;

    let itemOffset = item.offsetTop;

    if (location.hash === "#request" && window.innerWidth < 1024) {
      const requestCnt = item.querySelector<HTMLElement>(".request__cnt");
      if (requestCnt) itemOffset += requestCnt.offsetTop;
    }

    window.scrollTo({
      top: itemOffset - headerOffset,
      behavior: "smooth",
    });
  }, 0);

  return false;
};

export const onRouteUpdate: GatsbyBrowser["onRouteUpdate"] = ({ location }) => {
  Object.keys(urlParamsToSend).forEach(key => {
    const value = new URLSearchParams(location.search).get(key);
    if (value) window.localStorage.setItem(`_${key}`, value);
  });

  if (!window.localStorage.getItem("_landing_url")) {
    window.localStorage.setItem("_landing_url", location.href);
  }

  if (!window.localStorage.getItem("_referrer")) {
    const referrer = document?.referrer;
    if (referrer) {
      const url = new URL(referrer);
      if (url.hostname !== location.hostname) {
        window.localStorage.setItem("_referrer", referrer);
      }
    }
  }
};
