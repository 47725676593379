declare const RECAPTCHA_KEY: string;
declare const grecaptcha: any;

export function includeCaptcha() {
  const script = document.createElement("script");

  script.src = `https://www.google.com/recaptcha/enterprise.js?render=${RECAPTCHA_KEY}`;
  script.defer = true;

  document.body.appendChild(script);
}

export async function runCaptcha(action: string): Promise<string> {
  return new Promise(resolve => {
    grecaptcha.enterprise.ready(async () => {
      const token = await grecaptcha.enterprise.execute(RECAPTCHA_KEY, { action });
      resolve(token);
    });
  });
}
